import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  useUserQuery,
  useOrdersQuery,
  useCompanyLazyQuery,
  useUpdateUserMutation,
  useUserPermissionsQuery,
  useUpdateCompanyMutation,
  useCompanyOcrPagesLeftQuery,
  useCompanySubscriptionQuery,
  useCompanyPaymentMethodQuery,
  useCompanyAccountBalanceQuery,
  useInviteUserToCompanyMutation,
  useDeleteUserFromCompanyMutation,
  useCancelCompanySubscriptionMutation,
  useResumeCompanySubscriptionMutation,
  useRemoveCompanyPaymentMethodMutation,
} from "src/api/graphql/generated/gql-hooks";
import {
  Company,
  UpdateUserInput,
  UpdateCompanyInput,
  GetOrdersOptionsInput,
  InviteUserToCompanyInput,
} from "src/api/graphql/types-and-hooks";
import { useToastError, useToastSuccess } from "src/common/hooks/useToast";
import { getFormErrors } from "src/utils/getFormErrors";

export const useGetUser = (userId: string) => {
  const { loading, data } = useUserQuery({
    variables: { userId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const user = data?.user;

  return {
    loading,
    user,
  };
};

export const useLazyGetCompany = () => {
  const [getCompany, { loading, data }] = useCompanyLazyQuery({
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const company = data?.company;

  return {
    getCompany: async (companyId: string) => {
      await getCompany({ variables: { companyId } });
    },
    loading,
    company,
  };
};

export const useEditUser = () => {
  const [editUser, { loading: isUpdatingUser }] = useUpdateUserMutation({
    onCompleted: () => {
      useToastSuccess("Zapisano");
    },
    refetchQueries: () => ["User"],
    onError: () => useToastError("Wystąpił błąd"),
  });

  return {
    editUser: async (updateUserInput: UpdateUserInput) => {
      await editUser({
        variables: {
          updateUserInput,
        },
      });
    },
    isUpdatingUser,
  };
};

export const useEditCompany = () => {
  const [editCompany, { loading: isUpdatingCompany }] = useUpdateCompanyMutation({
    onCompleted: () => {
      useToastSuccess("Zapisano dane firmy");
    },
    onError: () => useToastError("Wystąpił błąd podczas zapisywania danych firmy"),
  });

  return {
    editCompany: async (updateCompanyInput: UpdateCompanyInput) => {
      await editCompany({
        variables: {
          updateCompanyInput,
        },
      });
    },
    isUpdatingCompany,
  };
};

export const useDeleteUserFromCompany = (companyId: string) => {
  const [deleteUserFromCompany, { loading }] = useDeleteUserFromCompanyMutation({
    onCompleted: () => {
      useToastSuccess("Użytkownik został usunięty");
    },
    onError: () => useToastError("Wystąpił błąd"),
    refetchQueries: () => ["Company"],
    awaitRefetchQueries: true,
  });

  return {
    handleDeleteUserFromCompany: async (input: { userId: string }) => {
      await deleteUserFromCompany({ variables: { deleteUserFromCompanyInput: { ...input, companyId } } });
    },
    isDeleting: loading,
  };
};

export const useInviteUserToCompany = () => {
  const [errors, setErrors] = useState<any>({});
  const [inviteUserToCompany, { loading, data }] = useInviteUserToCompanyMutation({
    onCompleted: () => {
      useToastSuccess("Zaproszenie zostało wysłane");
    },
    onError: (error) => {
      useToastError("Wystąpił błąd");
      if (error) {
        setErrors(getFormErrors(error?.graphQLErrors || []));
      }
    },
    errorPolicy: "all",
  });

  return {
    handleInviteUserToCompany: async (input: InviteUserToCompanyInput) => {
      await inviteUserToCompany({ variables: { inviteUserToCompanyInput: { ...input } } });
    },
    errors,
    loading,
    data,
  };
};

export const useOrders = (options: GetOrdersOptionsInput) => {
  const { loading, data } = useOrdersQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const orders = data?.orders?.documents || [];
  const total = data?.orders?.total;

  return {
    loading,
    orders,
    total,
  };
};

export const useCompanySubscription = (companyId: string) => {
  const { data, loading: companySubscriptionLoading } = useCompanySubscriptionQuery({ variables: { companyId } });

  const subscription = data?.company?.subscription;
  const newSubscription = data?.company?.newSubscription;

  return {
    subscription,
    newSubscription,
    companySubscriptionLoading,
  };
};

export const useCancelCompanySubscription = () => {
  const { t } = useTranslation();
  const [cancelCompanySubscription, { loading: isCancelingSubscription, data }] = useCancelCompanySubscriptionMutation({
    onCompleted: () => useToastSuccess(t("Subscription-was-cancelled")),
    onError: () => useToastError(),
    refetchQueries: () => ["CompanySubscription"],
  });

  return {
    cancelCompanySubscription: async () => {
      await cancelCompanySubscription();
    },
    isCancelingSubscription,
    isCancelled: !!data,
  };
};

export const useResumeCompanySubscription = () => {
  const { t } = useTranslation();
  const [resumeCompanySubscription, { loading: isResumingSubscription }] = useResumeCompanySubscriptionMutation({
    onCompleted: () => useToastSuccess(t("Subscription-was-resumed")),
    onError: () => useToastError(),
    refetchQueries: () => ["CompanySubscription"],
  });

  return {
    resumeCompanySubscription: async () => {
      await resumeCompanySubscription();
    },
    isResumingSubscription,
  };
};

export const useCompanyPaymentMethod = (companyId: string) => {
  const { loading, data } = useCompanyPaymentMethodQuery({
    variables: { companyId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const paymentMethod = data?.company?.paymentMethod;

  return {
    paymentMethodLoading: loading,
    paymentMethod,
  };
};

export const useRemovePaymentMethod = () => {
  const [removeCompanyPaymentMethod, { loading: isRemovingPaymentMethod }] = useRemoveCompanyPaymentMethodMutation({
    onCompleted: () => useToastSuccess(),
    onError: () => useToastError(),
    refetchQueries: () => ["CompanyPaymentMethod"],
  });

  return {
    removePaymentMethod: async () => {
      await removeCompanyPaymentMethod({
        variables: {},
      });
    },
    isRemovingPaymentMethod,
  };
};

export const useUserPermissions = (userId: string) => {
  const { loading, data } = useUserPermissionsQuery({
    variables: { id: userId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });
  const permissions = data?.userPermissions?.permissions || [];

  return {
    loading,
    permissions,
  };
};

export const useCompanyOcrPagesLeft = (companyId: string) => {
  const { loading, data } = useCompanyOcrPagesLeftQuery({
    variables: { companyId },
    onError: () => useToastError(),
    fetchPolicy: "cache-and-network",
  });

  const ocrPagesLeft = data?.company?.ocrPagesLeft + data?.company?.additionalOcrPages;

  return {
    companyOcrPagesLeftLoading: loading,
    ocrPagesLeft,
  };
};

export const useCompanyAccountBalance = (companyId: string) => {
  const { loading, data } = useCompanyAccountBalanceQuery({
    variables: { companyId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const companyAccountBalance = data?.company || ({} as Partial<Company>);

  return {
    loading,
    companyAccountBalance,
  };
};
