import React, { useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import CloudTax, { CloudTaxColorEnum } from "src/components/ui/CloudTax";

import { useCompanyTaxDetails, useIncomeTax, useVatTax } from "src/pages/cockpit/cockpit.hooks";

import { TaxTypes, VatTaxTypes } from "src/api/graphql/types-and-hooks";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";

type IncomeTaxProps = {
  taxType: TaxTypes;
  companyTaxesLoading: boolean;
};

type VatTaxProps = {
  isVatPayer: boolean;
  vatTaxType: VatTaxTypes;
  companyTaxesLoading: boolean;
};

const IncomeTax: React.FC<IncomeTaxProps> = ({ taxType, companyTaxesLoading }) => {
  const currentUser = useCurrentUser();
  const taxesCalculationEnabled = currentUser?.company?.subscription?.subscription?.taxesCalculationEnabled;

  const { t } = useTranslation();
  const { getIncomeTax, loading, incomeTaxValue } = useIncomeTax();
  const lastMonthIncomeTax = useIncomeTax();

  useEffect(() => {
    if (taxesCalculationEnabled) {
      getIncomeTax({
        filter: {
          date: { gte: moment().startOf("month").unix(), lte: moment().endOf("month").unix() },
        },
      });
      lastMonthIncomeTax.getIncomeTax({
        filter: {
          date: {
            gte: moment().subtract(1, "month").startOf("month").unix(),
            lte: moment().subtract(1, "month").endOf("month").unix(),
          },
        },
      });
    }
  }, [taxesCalculationEnabled]);

  return (
    <CloudTax
      className={!taxesCalculationEnabled && "opacity-50"}
      color={CloudTaxColorEnum.green}
      title={`${t("Tax")} ${taxType ? taxType : ""}`}
      value={taxesCalculationEnabled ? incomeTaxValue : "-"}
      previousTaxValue={taxesCalculationEnabled ? lastMonthIncomeTax.incomeTaxValue : "-"}
      loading={companyTaxesLoading || loading || lastMonthIncomeTax?.loading}
    />
  );
};

const VatTax: React.FC<VatTaxProps> = ({ vatTaxType, isVatPayer, companyTaxesLoading }) => {
  const currentUser = useCurrentUser();
  const taxesCalculationEnabled = currentUser?.company?.subscription?.subscription?.taxesCalculationEnabled;

  const { t } = useTranslation();
  const { loading, vatTaxValue, getVatTax } = useVatTax();
  const previousVatTax = useVatTax();

  useEffect(() => {
    if (taxesCalculationEnabled && isVatPayer) {
      let currentMonthDates = { gte: moment().startOf("month").unix(), lte: moment().endOf("month").unix() };
      let lastMonthDates = {
        gte: moment().subtract(1, "month").startOf("month").unix(),
        lte: moment().subtract(1, "month").endOf("month").unix(),
      };

      if (vatTaxType === VatTaxTypes.Quarterly) {
        currentMonthDates = {
          gte: moment().startOf("quarter").unix(),
          lte: moment().endOf("quarter").unix(),
        };
        lastMonthDates = {
          gte: moment().subtract(1, "quarter").startOf("quarter").unix(),
          lte: moment().subtract(1, "quarter").endOf("quarter").unix(),
        };
      }

      getVatTax({
        filter: {
          date: currentMonthDates,
        },
      });
      previousVatTax.getVatTax({
        filter: {
          date: lastMonthDates,
        },
      });
    }
  }, [taxesCalculationEnabled, vatTaxType]);

  return (
    <CloudTax
      className={!isVatPayer || !taxesCalculationEnabled ? "opacity-50" : ""}
      title={`${t("Tax")} ${"VAT"}`}
      value={taxesCalculationEnabled ? vatTaxValue : "-"}
      color={CloudTaxColorEnum.purple}
      previousTaxValue={taxesCalculationEnabled ? previousVatTax.vatTaxValue : "-"}
      loading={companyTaxesLoading || loading || previousVatTax.loading}
    />
  );
};

const Taxes = () => {
  const currentUser = useCurrentUser();
  const { loading, taxDetails } = useCompanyTaxDetails(currentUser?.company?._id);

  return (
    <>
      <div className="basis-1/4">
        <VatTax isVatPayer={taxDetails?.isVatPayer} vatTaxType={taxDetails?.vatType} companyTaxesLoading={loading} />
      </div>
      <div className="basis-1/4">
        <IncomeTax taxType={taxDetails?.taxType} companyTaxesLoading={loading} />
      </div>
    </>
  );
};
export default Taxes;
