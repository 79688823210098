import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import Text from "src/components/ui/Text";
import AppInput, { InputTypes } from "src/components/form/AppInput";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";
import AuthWrapper from "src/components/ui/AuthWrapper";

import { useCurrentUser } from "src/common/AuthProvider/authProvider.hooks";
import {
  getForgotPasswordPath,
  getRegisterPath,
  getCockpitPath,
  getSelectSubscriptionPath,
} from "src/common/router/routerPaths";

import EyeIcon from "src/assets/images/eye.svg";

import loginSchema from "./login.schema";
import { useLogin } from "./login.hooks";

const Login = () => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const isLogged = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { handleLogin, loading } = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    reValidateMode: "onChange",
  });

  const loginButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && loginButtonRef.current) {
        loginButtonRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (isLogged) {
      if (currentUser?.subscribed) {
        return navigate(getCockpitPath());
      }
      return navigate(getSelectSubscriptionPath());
    }
  }, [isLogged, currentUser]);

  return (
    <AuthWrapper>
      <div className="flex flex-col mt-12 space-y-4 items-center w-full">
        <div className="flex flex-col space-y-2 items-center">
          <Text className="text-white 2xl:text-28 xl:text-24 text-20">Witaj</Text>
          <Text className="text-text-secondary 2xl:text-20 xl:text-18 text-14">
            Zaloguj się i korzystaj z pełnego potencjału swojej firmy
          </Text>
        </div>
        <div className="flex flex-col space-y-4 max-w-[75%] sm:max-w-[50%] w-full">
          <AppInput
            id="email"
            inputType={InputTypes.TEXT}
            placeholder="Adres e-mail"
            register={register}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !errors?.email && "border-white/20 focus:border-white/60"
            }`}
            error={!!errors?.email}
          />
          <AppInput
            iconEnd={EyeIcon}
            id="password"
            inputType={InputTypes.PASSWORD}
            placeholder="Hasło"
            register={register}
            inputClasses={`font-sf-pro-medium text-white 2xl:h-10 xl:h-9 sm:h-7 h-9 text-12 2xl:text-16 xl:text-14 sm:text-10 ${
              !errors?.password && "border-white/20 focus:border-white/60"
            }`}
            error={!!errors?.password}
          />
          <Text
            className="text-right text-blue underline mt-5 cursor-pointer text-12 2xl:text-16 xl:text-14 sm:text-10"
            onClick={() => navigate(getForgotPasswordPath())}
          >
            Przypomnij hasło
          </Text>
          <AppButton
            ref={loginButtonRef}
            color={ButtonColorTypes.PRIMARY}
            customClass="rounded-[12px] h-8 2xl:h-11 xl:h-9 sm:h-7"
            onClick={handleSubmit(handleLogin)}
            title={t("Log-in")}
            isLoading={loading}
            textClass="text-white 2xl:text-20 xl:text-18 text-14 font-sf-pro-medium"
          />
          <Text
            className="text-text-secondary text-center cursor-pointer text-12 2xl:text-16 xl:text-14 sm:text-10"
            onClick={() => navigate(getRegisterPath())}
          >
            Nie masz konta? &nbsp;
            <Text className="font-sf-pro-medium text-white text-12 2xl:text-16 xl:text-14 sm:text-10">
              Zarejestruj się
            </Text>
          </Text>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default Login;
