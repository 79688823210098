import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";

import AppSwitch from "src/components/form/AppSwitch";
import AppButton, { ButtonColorTypes } from "src/components/form/AppButton";

// import HintIcon from "src/assets/images/hint.svg";
import ChevronLeftIcon from "src/assets/images/chevron-left.svg";
import ChevronRightIcon from "src/assets/images/chevron-right.svg";

import Text from "./Text";

export enum CalendarView {
  Month = "month",
  Week = "week",
  Day = "day",
}

export enum ChangeDateType {
  Prev = "prev",
  Next = "next",
  Today = "today",
}

type Props = {
  calendarView: CalendarView;
  calendarViews: { value: string; label: string }[];
  onShowHints?: () => void;
  onDateChange: (date: Moment) => void;
  onCalendarViewChange: (calendarView: CalendarView) => void;
};

const TimeRangeToolbar: React.FC<Props> = ({
  onShowHints,
  onDateChange,
  calendarView,
  calendarViews,
  onCalendarViewChange,
}) => {
  const { t } = useTranslation();

  const [momentDate, setMomentDate] = useState(moment());
  const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));
  const [currentWeek, setCurrentWeek] = useState<Moment[]>([]); // [start, end]
  const [currentMonth, setCurrentMonth] = useState(moment().format("MMMM"));

  useEffect(() => {
    onDateChange(momentDate);
  }, [momentDate]);

  useEffect(() => {
    const newDate = moment();
    setMomentDate(newDate);

    if (calendarView === CalendarView.Week) {
      setCurrentMonth(newDate.format("MMMM"));
      setCurrentWeek([newDate.clone().startOf("isoWeek"), newDate.clone().endOf("isoWeek")]);
    }
    if (calendarView === CalendarView.Month) {
      setCurrentMonth(newDate.format("MMMM"));
      setCurrentYear(newDate.format("YYYY"));
    }
  }, [calendarView]);

  const onChangeWeek = (type?: ChangeDateType) => {
    const newDate = type === ChangeDateType.Today ? moment() : momentDate.clone();
    if (type === ChangeDateType.Prev) newDate.subtract(1, "week");
    if (type === ChangeDateType.Next) newDate.add(1, "week");
    setMomentDate(newDate);
    setCurrentWeek([newDate.clone().startOf("isoWeek"), newDate.clone().endOf("isoWeek")]);
    setCurrentMonth(newDate.format("MMMM"));
  };

  const onChangeMonth = (type?: ChangeDateType) => {
    const newDate = type === ChangeDateType.Today ? moment() : momentDate.clone();
    if (type === ChangeDateType.Prev) newDate.subtract(1, "month");
    if (type === ChangeDateType.Next) newDate.add(1, "month");
    setMomentDate(newDate);
    setCurrentMonth(newDate.format("MMMM"));
    setCurrentYear(newDate.format("YYYY"));
  };

  const onChangeDate = (type?: ChangeDateType) => {
    if (calendarView === CalendarView.Week) onChangeWeek(type);
    if (calendarView === CalendarView.Month) onChangeMonth(type);
  };

  return (
    <div className="flex flex-row mb-5">
      <div className="flex flex-row space-x-4 basis-1/2">
        <div className="toolbar-hint">
          <AppSwitch
            bgColor="bg-[#F4F4F41A]"
            optActiveWidth="min-w-[80px]"
            optInactiveWidth="min-w-[80px]"
            activeTextClass="text-white font-sf-pro-medium"
            options={calendarViews}
            onChange={(val) => onCalendarViewChange(val as CalendarView)}
          />
        </div>
        {/* {calendarView === CalendarView.Month ? (
          <img src={HintIcon} className="cursor-pointer" onClick={onShowHints} />
        ) : undefined} */}
      </div>
      {calendarView !== CalendarView.Day && (
        <div className="basis-1/2 flex flex-row space-x-4 items-center justify-end">
          <img src={ChevronLeftIcon} className="cursor-pointer" onClick={() => onChangeDate(ChangeDateType.Prev)} />
          {calendarView === CalendarView.Month && <Text>{`${t(currentMonth)} ${currentYear}`}</Text>}
          {calendarView === CalendarView.Week && (
            <Text>{`${t(currentMonth)} ${currentWeek[0]?.format("DD.MM")} - ${currentWeek[1]?.format("DD.MM")}`}</Text>
          )}
          <img src={ChevronRightIcon} className="cursor-pointer" onClick={() => onChangeDate(ChangeDateType.Next)} />
          <div>
            <AppButton
              title={t("Today")}
              onClick={() => onChangeDate(ChangeDateType.Today)}
              color={ButtonColorTypes.SECONDARY}
              customClass="bg-[#1C1C1C0D] rounded-[8px]"
              textClass="text-black"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeRangeToolbar;
