import moment from "moment";

import {
  useVatTaxLazyQuery,
  useIncomeTaxLazyQuery,
  useUnpaidInvoicesQuery,
  useCompanyTaxDetailsQuery,
  useMonthlyLiquiditiesQuery,
  useCompanyAccountBalanceQuery,
  useTransactionsConditionQuery,
  GetLiquidityAnalysisOptionsInput,
  useBudgetCostsAndIncomesPerMonthQuery,
} from "src/api/graphql/generated/gql-hooks";
import {
  Company,
  MonthlyLiquidity,
  CompanyTaxDetails,
  UnpaidInvoicesResponse,
  GetVatTaxArgsOptionsInput,
  GetIncomeTaxArgsOptionsInput,
  BudgetCostsAndIncomesPerMonth,
  TransactionsConditionResponse,
  GetTransactionsConditionOptionsInput,
} from "src/api/graphql/types-and-hooks";

import { useToastError } from "src/common/hooks/useToast";

export const useMonthlyLiquidities = (options: GetLiquidityAnalysisOptionsInput) => {
  const { loading, data } = useMonthlyLiquiditiesQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const monthlyLiquidities = data?.monthlyLiquidities?.documents || [];

  return {
    liquidityLoading: loading,
    monthlyLiquidities,
  };
};

export const useUnpaidInvoices = () => {
  const { loading, data } = useUnpaidInvoicesQuery({
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const unpaidInvoices = data?.unpaidInvoices || ({} as UnpaidInvoicesResponse);

  return {
    unpaidInvoicesLoading: loading,
    unpaidInvoices,
  };
};

export const useTransactionsCondition = (options: GetTransactionsConditionOptionsInput) => {
  const { loading, data } = useTransactionsConditionQuery({
    variables: { options },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const transactionsCondition = data?.transactionsCondition || ({} as TransactionsConditionResponse);

  return {
    loading,
    transactionsCondition,
  };
};

export const useCompanyAccountBalance = (companyId: string) => {
  const { loading, data } = useCompanyAccountBalanceQuery({
    variables: { companyId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const companyAccountBalance = data?.company || ({} as Partial<Company>);

  return {
    loading,
    companyAccountBalance,
  };
};

export const useBudgetCostsAndIncomesBalance = (name: string, isDefault: boolean) => {
  const { loading, data } = useBudgetCostsAndIncomesPerMonthQuery({
    variables: { name, isDefault },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const costsAndIncomesPerMonth =
    data?.budgetCostsAndIncomesPerMonth?.costsAndIncomesPerMonth || ([] as BudgetCostsAndIncomesPerMonth[]);

  return {
    loading,
    costsAndIncomesPerMonth,
  };
};

export const useMonthlyLiquiditiesChartData = (monthlyLiquidities: MonthlyLiquidity[]) => {
  const currentMonth = moment().format("MMM YYYY");

  const monthlyLiquiditiesChartData = monthlyLiquidities.map((d) => {
    if (moment(d.month).isSame(currentMonth, "month")) {
      return { month: d.month, liquidity: d.liquidity, prognosisLiquidity: d.liquidity };
    }
    if (moment(d.month).isAfter(moment(currentMonth))) {
      return { month: d.month, prognosisLiquidity: d.liquidity };
    }
    return { month: d.month, liquidity: d.liquidity };
  });

  return monthlyLiquiditiesChartData;
};

export const useIncomeTax = () => {
  const [getIncomeTax, { loading, data }] = useIncomeTaxLazyQuery({
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const incomeTaxValue = data?.incomeTax.incomeTaxValue || 0;

  return {
    loading,
    incomeTaxValue,
    getIncomeTax: (options: GetIncomeTaxArgsOptionsInput) => {
      getIncomeTax({ variables: { options } });
    },
  };
};

export const useVatTax = () => {
  const [getVatTax, { loading, data }] = useVatTaxLazyQuery({
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const vatTaxValue = data?.vatTax.vatTaxValue || 0;

  return {
    loading,
    getVatTax: (options: GetVatTaxArgsOptionsInput) => {
      getVatTax({ variables: { options } });
    },
    vatTaxValue,
  };
};

export const useCompanyTaxDetails = (companyId: string) => {
  const { loading, data } = useCompanyTaxDetailsQuery({
    variables: { companyId },
    onError: () => useToastError("Wystąpił błąd"),
    fetchPolicy: "cache-and-network",
  });

  const taxDetails = data?.company?.taxDetails || ({} as CompanyTaxDetails);

  return {
    loading,
    taxDetails,
  };
};
