import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Cloud, { CloudColorEnum } from "src/components/ui/Cloud";

import { getInvoicesPath } from "src/common/router/routerPaths";
import { useUnpaidInvoices } from "src/pages/cockpit/cockpit.hooks";

const OverdueTransactions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { unpaidInvoices, unpaidInvoicesLoading } = useUnpaidInvoices();

  const getInvoiceWordForm = (count: number) => {
    if (count === 1) {
      return "faktura";
    } else if (count > 1 && count < 5) {
      return "faktury";
    } else {
      return "faktur";
    }
  };

  return (
    <>
      <div className="basis-1/4">
        <Cloud
          color={CloudColorEnum.red}
          title={`${t("Overdue-obligations")}`}
          value={unpaidInvoices?.unpaidCosts}
          loading={unpaidInvoicesLoading}
          subtitle={`${unpaidInvoices?.unpaidCostsCount} ${getInvoiceWordForm(unpaidInvoices?.unpaidCostsCount)}`}
          onClick={() => unpaidInvoices?.unpaidCostsCount && navigate(getInvoicesPath({ showUnpaidCosts: true }))}
        />
      </div>
      <div className="basis-1/4">
        <Cloud
          color={CloudColorEnum.red}
          title={`${t("Overdue-receivables")}`}
          value={unpaidInvoices?.unpaidIncomes}
          loading={unpaidInvoicesLoading}
          subtitle={`${unpaidInvoices?.unpaidIncomesCount} ${getInvoiceWordForm(unpaidInvoices?.unpaidIncomesCount)}`}
          onClick={() => unpaidInvoices?.unpaidIncomesCount && navigate(getInvoicesPath({ showUnpaidIncomes: true }))}
        />
      </div>
    </>
  );
};

export default OverdueTransactions;
